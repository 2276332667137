import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'

const browserLocale = navigator.language.split('-')[0]

const formatDateTime = (dateTime) => {
  return browserLocale === 'ja'
    ? format(dateTime, 'yyyy/MM/dd (eee) HH:mm', { locale: ja })
    : format(dateTime, 'yyyy/MM/dd (eee) HH:mm')
}

export { browserLocale, formatDateTime }
