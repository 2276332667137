import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'signIn',
    meta: { enableAppBar: false, signInRequired: false },
    // component: SignInView
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignInView')
  },
  {
    path: '/sign-out',
    name: 'signOut',
    meta: { enableAppBar: true, appBarTitle: 'サインアウト', signInRequired: true },
    component: () => import(/* webpackChunkName: "signOut" */ '../views/SignOutView')
  },
  {
    path: '/clients',
    name: 'clients',
    meta: { enableAppBar: true, appBarTitle: 'クライアント', signInRequired: true },
    component: () => import(/* webpackChunkName: "clients" */ '../views/ClientsView')
  },
  {
    path: '/stores',
    name: 'storess',
    meta: { enableAppBar: true, appBarTitle: '店舗（Under Construction - DO NOT USE）', signInRequired: true },
    component: () => import(/* webpackChunkName: "stores" */ '../views/StoresView')
  },
  {
    path: '/samples',
    name: 'samples',
    meta: { enableAppBar: true, appBarTitle: 'サンプル テンプレート', signInRequired: true },
    component: () => import(/* webpackChunkName: "samples" */ '../views/SamplesView')
  },
  {
    path: '/organizations',
    name: 'organizations',
    meta: { enableAppBar: true, appBarTitle: 'チーム', signInRequired: true },
    component: () => import(/* webpackChunkName: "organizations" */ '../views/OrganizationsView')
  },
  {
    path: '/internals',
    name: 'internals',
    meta: { enableAppBar: true, appBarTitle: 'Internal Use', signInRequired: true },
    component: () => import(/* webpackChunkName: "internals" */ '../views/InternalsView')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const { meta } = to
  if (meta.signInRequired) {
    if (store.state.auth.user === null) {
      next({ name: 'signIn', replace: true })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
