<template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="enableAppBar">
      <v-app-bar-nav-icon @click="navClicked" />
      <v-spacer></v-spacer>
      <v-app-bar-title :truncate="false">{{ appBarTitle }}</v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <navigation-drawer :show="showNavigationDrawer" @change="navChanged"></navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from './components/NavigationDrawer'

export default {
  name: 'App',
  components: {
    NavigationDrawer
  },
  data: () => ({
    showNavigationDrawer: false
  }),
  computed: {
    enableAppBar() {
      return this.$route.meta.enableAppBar
    },
    appBarTitle() {
      return this.$route.meta.appBarTitle
    }
  },
  methods: {
    navClicked() {
      this.showNavigationDrawer = true
    },
    navChanged(value) {
      this.showNavigationDrawer = value
    }
  }
}
</script>
