export const state = () => ({
  user: null
})

export const mutations = {
  updateUser(state, value) {
    state.user = { ...value }
  },
  clearUser(state) {
    state.user = null
  }
}
