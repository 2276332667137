<template>
  <v-navigation-drawer v-model="show_" app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">TRUFFLE.FAN™ v2 Admin</v-list-item-title>
        <!-- <v-list-item-subtitle>{{}}</v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item link to="/organizations">
        <v-list-item-icon>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>チーム</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/clients">
        <v-list-item-icon>
          <v-icon>mdi-account-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>クライアント</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item link to="/stores">
        <v-list-item-icon>
          <v-icon>mdi-store-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>店舗（Under Construction - DO NOT USE）</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- <v-list-item link to="/samples">
        <v-list-item-icon>
          <v-icon>mdi-message-text-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>サンプル テンプレート</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item link to="/internals">
        <v-list-item-icon>
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Internal Use</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item link to="/sign-out">
        <v-list-item-icon>
          <v-icon color="red">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><span class="red--text">サインアウト</span></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    show_: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  }
}
</script>
