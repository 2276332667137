import { extend, localize } from 'vee-validate'
// eslint-disable-next-line camelcase
import {
  required,
  email,
  min,
  confirmed,
  regex,
  numeric,
  max,
  integer,
  max_value,
  min_value
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import ja from 'vee-validate/dist/locale/ja.json'
import { browserLocale } from '@/utils'

extend('required', required)
extend('email', email)
extend('numeric', numeric)
extend('min', min)
extend('max', max)
extend('max_value', max_value)
extend('min_value', min_value)
extend('integer', integer)
extend('confirmed', confirmed)
extend('regex', regex)

extend('unique', {
  validate: (email, { emails }) => {
    return emails ? !emails.includes(email) : true
  },
  params: ['emails'],
  message: 'メールアドレスが重複しています。違うメールアドレスを入力してください。'
})

if (browserLocale === 'ja') {
  localize('ja', ja)
} else {
  localize('en', en)
}
